import { PostMeta, rawDateToDate } from "../renderer/types.js";

import rawPostMeta from "./rawPostMeta.json";

const smartypants = (text: string) => {
  return text
    .replaceAll("--", "–")
    .replaceAll("---", "—")
    .replaceAll("...", "…")
    .replaceAll("'", "’")
    .replace(/"(?=\w)/g, "“")
    .replaceAll('"', "”");
};

export const postMeta = Object.values(rawPostMeta)
  .filter(
    (meta) =>
      process.env.NODE_ENV === "development" ||
      !("draft" in meta) ||
      !meta.draft,
  )
  .map(
    (meta): PostMeta => ({
      ...meta,
      title: smartypants(meta.title),
      subtitle: smartypants(meta.subtitle),
      date: rawDateToDate(meta.date),
    }),
  )
  .sort((a, b) => (a.date > b.date ? -1 : 1));
